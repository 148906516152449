import { defineStore } from "pinia";
import { fetchFooter } from "@/services/footerService";

export const useFooterStore = defineStore("footer", {
  state: () => ({
    footer: null,
  }),
  actions: {
    async loadFooter() {
      if (!this.footer) {
        this.footer = await fetchFooter();
      }
    },
  },
});
