<template v-if="leaderboardStats">
  <section class="mt-[160px] px-6 md:px-[96px] max-w-screen m-full">
    <h2 v-html="title" class="text-headline-medium mb-9 text-onSurfaceLight" />
    <div class="grid grid-cols-7 md:grid-cols-13 gap-8 grid-rows-leaderboard">
      <div
        class="relative bg-surfaceLight leaderboard-card col-span-7 md:col-span-7 lg:col-span-5 z-0"
      >
        <p class="text-headline-small text-[#525252] max-w-[75%]">
          Meeste koekjes gegeten op kantoor
        </p>
        <div
          class="absolute bottom-0 right-0 px-10 py-8 flex flex-col lg:items-center text-primary items-end"
        >
          <p class="text-headline-medium">#1</p>
          <p class="text-headline-medium">
            {{ leaderboardStats.meeste_koekjes_persoon ?? "Jaap" }}
          </p>
        </div>
        <img
          class="absolute -z-10"
          width="190px"
          src="@/assets/koekje.png"
          alt=""
        />
      </div>
      <div
        class="bg-surfaceDark leaderboard-card col-span-7 md:col-span-6 lg:col-span-3"
      >
        <div class="text-headline-medium text-primary md:text-[2vw]">
          <p class="overflow-hidden break-all">
            {{ leaderboardStats.regels_code }}
          </p>
          <p class="">regels</p>
        </div>
        <p class="text-body-medium text-onSurfaceDark">
          Regels code geschreven
        </p>
      </div>
      <div
        class="relative overflow-hidden bg-surfaceLight leaderboard-card col-span-7 md:col-span-7 lg:col-span-5 z-0"
      >
        <p class="text-headline-small text-onSurfaceLight pr-8">
          Meeste spotify minuten per jaar
        </p>
        <div>
          <p
            class="text-headline-medium text-primary pb-3"
            v-html="
              leaderboardStats.spotify_minuten
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            "
          />
          <p
            class="text-headline-small text-primary"
            v-html="leaderboardStats.spotify.persoon"
          />
        </div>
        <img
          class="absolute -bottom-[20%] -right-[10%] animate-spin -z-10 duration-3500 w-[40%] max-w-[200px]"
          src="@/assets/plaat.png"
          alt=""
        />
      </div>
      <div
        class="relative bg-surfaceDark leaderboard-card div4 overflow-hidden z-0 md:col-span-6 col-span-7 lg:col-span-3"
      >
        <Vue3Lottie
          class="absolute -bottom-32 -left-32 -z-10"
          ref="lottieAnimation"
          animation-link="https://lottie.host/bef23ee0-d0ca-439a-8b82-3e4878bd2991/0zYzyIegui.json"
          :height="550"
          :width="550"
          :speed="0.2"
        />
        <div class="flex gap-5 items-center flex-grow">
          <p
            class="text-headline-medium text-primary"
            v-html="(leaderboardStats.totalCups ?? 0) + ' bakkies'"
          />
        </div>
        <p class="text-body-medium text-onSurfaceDark">
          Koppen koffie gedronken
        </p>
      </div>
      <div
        class="bg-surfaceLight leaderboard-card col-span-7 md:col-span-6 lg:col-span-4"
      >
        <div>
          <p
            class="text-headline-medium text-primary mb-2"
            v-html="leaderboardStats.grappenmaker"
          />
          <p class="text-body-medium text-primary">“Wij van WC eend...”</p>
        </div>
        <p class="text-body-medium">Grootste grappenmaker</p>
      </div>
      <div
        class="relative bg-surfaceDark leaderboard-card div6 overflow-hidden z-0 col-span-7 md:col-span-7 lg:col-span-6"
      >
        <p
          class="text-body-medium text-primary text-8xl flex items-center h-full"
          v-html="leaderboardStats.aantal_meetings"
        />
        <p
          class="absolute text-headline-large text-[132px] text-[#314147] -top-5 -left-1 -z-10 select-none whitespace-nowrap"
        >
          BLA BLA
        </p>
        <p
          class="invisible lg:visible absolute text-headline-large text-[132px] text-[#314147] -bottom-5 -z-10 -left-36 select-none whitespace-nowrap"
        >
          BLA BLA BLA
        </p>
        <p class="text-body-medium text-onSurfaceDark">Aantal meetings</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeScreenLeaderboardSection",
  props: {
    leaderboardStats: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
