<template>
  <div class="w-full">
    <div class="p-6 bg-surfaceDark md:mx-8 md:mt-8 md:rounded-3xl">
      <nav-bar-content :is-dark-background="true" />
    </div>
  </div>
</template>

<script>
import NavBarContent from "@/components/NavBarContent.vue";
export default {
  name: "NavigationBar",
  components: { NavBarContent },
};
</script>
