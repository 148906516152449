<template>
    <div class="flex flex-col min-w-[140px] w-full">
        <label class="text-label-small-variant text-onSurface" for="name">{{ label }}<span
                class="text-error p-[2px]">*</span></label>
        <input :id="label" type="text"
            class="border-2 border-surfaceShadow rounded-l px-4 py-3 text-body-medium placeholder:text-surfaceShadow w-full"
            :placeholder="label" />
    </div>
</template>

<script>
export default {
    name: "InputForm",
    props: {
        label: {
            type: String,
            default: "Label",
        },
    },
};
</script>