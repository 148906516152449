<template>
  <div class="w-full">
    <section
      class="relative bg-cover bg-center max-h-[344px] min-h-[180px] h-[30vw] bg-surfaceDark text-onSurfaceDark md:m-8 p-10 md:rounded-[32px] overflow-hidden z-0 flex items-center justify-center"
      :style="backgroundImage"
    >
      <div
        v-if="backgroundUrl"
        class="absolute inset-0 bg-black opacity-60 z-10"
      ></div>
      <h1 class="relative z-20 text-white text-title-phone md:text-title">
        {{ title }}
      </h1>
    </section>
  </div>
</template>

<script>
export default {
  name: "HeroBanner",
  props: {
    backgroundUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url(${this.backgroundUrl})`,
      };
    },
  },
};
</script>
