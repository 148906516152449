import { defineStore } from "pinia";
import { fetchHome } from "@/services/homeService";

export const useHomeStore = defineStore("home", {
  state: () => ({
    home: null,
  }),
  actions: {
    async loadHome() {
      if (!this.home) {
        this.home = await fetchHome();
      }
    },
  },
});
