<template>
  <div class="p-2 flex gap-1 rounded-full bg-[#314147]">
    <img :src="icon" class="w-4 h-4" />
    <p
      class="text-onSurfaceDark text-label-small-variant overflow-hidden text-nowrap"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "EmployeeCardTag",
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
