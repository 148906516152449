import qs from "qs";
import { baseUrl } from "@/config";

const Casesquery = qs.stringify({
  pLevel: 6,
});

const Casequery = qs.stringify({
  pLevel: 3,
});

export const fetchCases = async () => {
  const url = new URL("/api/portfolio?", baseUrl);
  url.search = Casesquery;

  try {
    const response = await fetch(url.href, { cache: "no-store" });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCase = async (id) => {
  const url = new URL(`/api/cases/${id}`, baseUrl);
  url.search = Casequery;

  try {
    const response = await fetch(url.href, { cache: "no-store" });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
