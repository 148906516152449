import qs from "qs";
import { baseUrl } from "@/config";

const homeQuery = qs.stringify(
  {
    pLevel: 5,
  },
  {
    encodeValuesOnly: true,
  }
);

export const fetchHome = async () => {
  const url = new URL("/api/home?", baseUrl);
  url.search = homeQuery;

  try {
    const response = await fetch(url.href, { cache: "no-store" });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
