<template>
  <analytics />
  <speed-insights />
  <router-view />
</template>

<script>
import { Analytics } from "@vercel/analytics/vue";
import { SpeedInsights } from "@vercel/speed-insights/vue";

export default {
  name: "App",
  components: {
    Analytics,
    SpeedInsights,
  },
};
</script>
