<template>
  <!-- buttons for desktop -->
  <div
    v-if="!isMobile()"
    class="flex justify-center items-center flex-wrap gap-y-3 mx-auto w-full max-w-[1096px]"
  >
    <button
      :class="[
        'm-auto text-label-large text-onSurface py-1s px-3 w-[200px] rounded-lg h-[62px]',
        { 'bg-surfaceDark': isSelected(service) },
        { 'text-onSurfaceDark': isSelected(service) },
      ]"
      v-for="service in services"
      :key="service.id"
      @click="selectService(service)"
    >
      {{ service.naam }}
    </button>
  </div>
  <!-- buttons for mobile -->
  <div
    v-else
    class="flex flex-col flex-wrap p-6 gap-y-3 mx-auto w-full max-w-[1096px]"
  >
    <pressable-surface-button
      v-for="service in services"
      :label="service.naam"
      :key="service.id"
      :onTap="() => selectService(service)"
      :class="[
        { 'bg-surfaceDark': isSelected(service) },
        { '!text-onSurfaceDark': isSelected(service) },
      ]"
    />
  </div>
</template>

<script>
import PressableSurfaceButton from "@/components/buttons/PressableSurfaceButton.vue";
export default {
  name: "ServiceSwitcher",
  components: {
    PressableSurfaceButton,
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedService: null,
    };
  },
  methods: {
    selectService(service) {
      this.selectedService = service;
      this.$emit("service-selected", service);
      window.location.hash = service.naam.toLowerCase().replace(/\s+/g, "-");
    },
    isSelected(service) {
      return this.selected && this.selected.id === service.id;
    },
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
