<template>
  <button
    @mouseup="handleClick"
    class="text-button shadow-button-shadow bg-primary text-onPrimary px-5 py-4 rounded-[10px] translate-y-[-4px] hover:bg-primaryHover active:bg-primaryHover active:translate-y-0 hover:translate-y-[-3px] transition ease-in-out active:shadow-button-shadow-active hover:shadow-button-shadow-hover"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "PressableButton",
  props: {
    label: {
      type: String,
      default: "Klik hier",
    },
    onTap: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isPressed: false,
    };
  },
  methods: {
    handleClick() {
      if (this.onTap && typeof this.onTap === "function") {
        setTimeout(() => {
          this.onTap();
        }, 250);
      }
    },
  },
};
</script>
