<template>
  <section
    class="relative flex w-full max-w-screen mx-6 md:px-[96px] gap-3 mt-24 px-6"
  >
    <div class="flex flex-col items-center w-full">
      <h2
        class="text-headline-medium text-onSurfaceLight mb-3"
        v-html="title"
      />
      <!-- Team cards -->
      <div class="grid grid-col-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
        <employee-card
          class=""
          v-for="employee in employees"
          :key="employee.id"
          :employee="employee"
        />
      </div>
    </div>
  </section>
</template>

<script>
import EmployeeCard from "@/components/EmployeeCard.vue";
export default {
  name: "TeamSection",
  components: {
    EmployeeCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
