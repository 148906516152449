<template>
  <div class="w-full md:px-[96px] px-6 flex justify-center">
    <section class="flex justify-between w-full max-w-[1248px] gap-6">
      <div class="max-w-[539px]">
        <h2
          v-if="caseName"
          v-html="caseName"
          class="text-headline-medium text-primary"
        />
        <p
          v-html="subtitle"
          class="text-body-medium text-onSurfaceLight py-5"
        />

        <pressable-button v-if="caseName" :label="`Bezoek ${caseName}`" />
      </div>
      <div
        class="text-onSurfaceLight text-body-medium flex flex-col gap-6 max-w-[400px]"
      >
        <div v-for="case_info in info" :key="case_info.id" class="">
          <h2
            class="text-headline-small pb-2"
            v-html="case_info.omschrijving"
          />
          <p v-html="case_info.informatie" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PressableButton from "@/components/buttons/PressableButton.vue";

export default {
  name: "CaseInfoSection",
  components: {
    PressableButton,
  },
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    info: {
      type: Array,
      required: true,
    },
    caseName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
