import qs from "qs";
import { baseUrl } from "@/config";

const query = qs.stringify(
  {
    pLevel: 5,
  },
  {
    encodeValuesOnly: true,
  }
);

export const fetchAboutUs = async () => {
  const url = new URL("/api/over-ons?", baseUrl);
  url.search = query;

  try {
    const response = await fetch(url.href, { cache: "no-store" });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
