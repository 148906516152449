<template>
  <div class="w-full mx-auto">
    <section
      class="relative bg-cover bg-center h-[100dvh] md:h-[calc(100vh-4rem)] max-h-[920px] bg-surfaceDark text-onSurfaceDark md:m-8 p-8 md:p-10 md:rounded-[32px] overflow-hidden z-0"
    >
      <NavBarContent />
      <div class="h-full max-w-screen mx-auto">
        <div
          class="absolute top-0 bottom-0 right-0 left-0 md:left-[50vw] bg-cover bg-blend-normal -z-10"
          :class="{
            'custom-bg-mobile': isMobileView,
            'custom-bg': !isMobileView,
          }"
        ></div>
        <div class="relative px-4 h-full flex flex-col">
          <div
            class="text-left max-w-[600px] flex flex-col justify-center flex-1"
          >
            <h2 class="text-headline-large mb-6">
              {{ title }}
            </h2>
            <p class="text-body-large mb-8">
              {{ subtitle }}
            </p>
            <div class="space-x-8">
              <pressable-button
                v-if="primaryButton"
                :label="primaryButton?.label"
                :onTap="() => $router.push(url)"
              />
              <a
                v-for="button in secondaryButtons"
                :key="button.id"
                @click="$router.push(button.url)"
                class="text-button cursor-pointer"
                v-html="button.label"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useWindowSize } from "@vueuse/core";
import NavBarContent from "./NavBarContent.vue";
import PressableButton from "./buttons/PressableButton.vue";
import { computed } from "vue";

export default {
  name: "HeroSection",
  components: {
    PressableButton,
    NavBarContent,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    primaryButton: {
      type: Object,
      required: true,
      validator: (value) => {
        return "label" in value && "url" in value;
      },
    },
    secondaryButtons: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((button) => "label" in button && "url" in button);
      },
    },
  },
  setup() {
    const { width } = useWindowSize();
    const isMobileView = computed(() => width.value < 860);

    return {
      isMobileView,
    };
  },
};
</script>

<style scoped>
.custom-bg {
  background-image: radial-gradient(
      circle closest-side,
      rgba(15, 35, 42, 0.4) 30%,
      rgba(15, 35, 42, 1) 100%
    ),
    url("@/assets/homescreen-hero.png");
}
.custom-bg-mobile {
  background-image: linear-gradient(
      rgba(15, 35, 42, 1) 0%,
      rgba(15, 35, 42, 0.7) 50%,
      rgba(15, 35, 42, 1) 95%
    ),
    url("@/assets/homescreen-hero.png");
}
</style>
