<template>
  <div class="flex flex-col items-center w-full flex-[1_0_auto]">
    <navigation-bar />
    <big-hero-banner-section
      v-if="caseItem?.case_informatie"
      :title="caseItem?.case_informatie?.naam"
      :subtitle="caseItem?.case_informatie?.slogan"
      :backgroundUrl="caseItem?.case_informatie?.hero_achtergrond_foto.url"
    />
    <!-- TODO: dynamisch maken -->
    <case-info-section
      v-if="caseItem && caseItem?.case_informatie"
      :caseName="caseItem?.case_informatie?.naam"
      :subtitle="caseItem?.case_informatie?.ondertitel"
      :info="caseItem?.case_informatie?.informatie_blok"
      :url="caseName?.case_informatie?.url"
    />
    <component
      v-for="(section, index) in caseItem?.pagina_secties"
      :key="index"
      :is="componentMapping[section.__component]"
      :text="section.tekst"
      :title="section.titel"
      :images="[section.foto_1?.url, section.foto_2?.url]"
      :image="section.foto?.url"
    />
  </div>
  <site-footer />
</template>

<script>
import NavigationBar from "@/components/globals/NavigationBar.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import { useCasesStore } from "@/stores/cases";
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import BigHeroBannerSection from "@/components/globals/sections/BigHeroBannerSection.vue";
import CaseInfoSection from "@/components/globals/sections/CaseInfoSection.vue";
import TextRightAlignedSection from "@/components/globals/sections/TextRightAlignedSection.vue";
import TextLeftAlignedSection from "@/components/globals/sections/TextLeftAlignedSection.vue";
import DoubleImageSection from "@/components/globals/sections/DoubleImageSection.vue";
import TextImageHorSection from "@/components/globals/sections/TextImageHorSection.vue";

export default {
  name: "CasePage",
  components: {
    NavigationBar,
    SiteFooter,
    BigHeroBannerSection,
    CaseInfoSection,
    TextRightAlignedSection,
    TextLeftAlignedSection,
    DoubleImageSection,
    TextImageHorSection,
  },
  setup() {
    const caseStore = useCasesStore();

    const componentMapping = {
      "secties.grote-hero-banner": "BigHeroBannerSection",
      "secties.tekst-met-foto-horizontaal": "TextImageHorSection",
      "secties.tekst-met-titel-rechts": "TextRightAlignedSection",
      "secties.tekst-met-titel-links": "TextLeftAlignedSection",
      "secties.dubbele-foto-sectie": "DoubleImageSection",
    };

    onMounted(async () => {
      await caseStore.loadCase(useRoute().params.id);
    });

    const caseItem = computed(() => caseStore.case);
    return {
      caseItem,
      componentMapping,
    };
  },
};
</script>
