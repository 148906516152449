<template>
  <div class="flex flex-col items-center w-full flex-[1_0_auto]">
    <navigation-bar />
    <hero-banner
      :background-url="aboutUs?.hero?.achtergrond_foto?.url"
      :title="aboutUs?.hero?.titel"
    />
    <div v-if="aboutUs" class="w-full">
      <component
        v-for="(section, index) in aboutUs.content"
        :key="index"
        :is="componentMapping[section.__component]"
        v-bind="section"
        :title="section.titel"
        :subtitle="section.ondertitel"
        :button="section.primaire_knop ?? section.knop"
        :secondaryButtons="section.tekst_knop"
        :text="section.tekst ?? section.uitleg"
        :clients="section.clients"
        :services="section.services"
        :monkeyText="section.aapje_tekst"
        :leaderboardStats="section"
        :image="section.foto?.url"
        :employees="section.team"
      />
    </div>
  </div>
  <site-footer />
</template>

<script>
import NavigationBar from "@/components/globals/NavigationBar.vue";
import HeroBanner from "@/components/HeroBanner.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import TextImageHorSection from "@/components/globals/sections/TextImageHorSection.vue";
import TextImageVertSection from "@/components/globals/sections/TextImageVertSection.vue";
import TextRightAlignedSection from "@/components/globals/sections/TextRightAlignedSection.vue";
import TextLeftAlignedSection from "@/components/globals/sections/TextLeftAlignedSection.vue";
import TeamSection from "@/components/globals/sections/TeamSection.vue";
import CallToActionSection from "@/components/globals/sections/CallToActionSection.vue";
import { useAboutUsStore } from "@/stores/aboutUs";
import { onMounted, computed } from "vue";

export default {
  name: "AboutUsPage",
  components: {
    NavigationBar,
    HeroBanner,
    SiteFooter,
    TextImageHorSection,
    TextImageVertSection,
    TextRightAlignedSection,
    TextLeftAlignedSection,
    TeamSection,
    CallToActionSection,
  },
  setup() {
    const aboutUsStore = useAboutUsStore();

    const componentMapping = {
      "secties.tekst-met-foto-horizontaal": "TextImageHorSection",
      "secties.tekst-met-foto-verticaal": "TextImageVertSection",
      "secties.team-sectie": "TeamSection",
      "secties.tekst-met-titel-rechts": "TextRightAlignedSection",
      "secties.tekst-met-titel-links": "TextLeftAlignedSection",
      "secties.call-to-action-gecentreerd": "CallToActionSection",
    };

    onMounted(async () => {
      await aboutUsStore.loadAboutUs();
    });

    const aboutUs = computed(() => aboutUsStore.aboutUs);

    return {
      aboutUs,
      componentMapping,
    };
  },
};
</script>
