<template>
  <div
    @click="onTap"
    :class="[
      'cursor-pointer',
      'shadow-button-shadow-surface',
      `translate-y-[-4px]`,
      'active:bg-surfaceHover',
      'active:translate-y-0',
      'transition',
      'ease-in-out',
      'active:shadow-button-shadow-surface-active',
      'hover:shadow-button-shadow-surface-hover',
      `hover:translate-y-[-2px]`,
      `hover:translate-y-[-2px]`,
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PressableWrapper",
  props: {
    onTap: {
      type: Function,
      required: false,
    },
  },
};
</script>
