import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./views/HomePage.vue";
import ContactPage from "./views/ContactPage.vue";
import CasesPage from "./views/CasesPage.vue";
import AboutUsPage from "./views/AboutUsPage.vue";
import ServicesPage from "./views/ServicesPage.vue";
import CasePage from "./views/CasePage.vue";
import ContentPage from "./views/ContentPage.vue";

const routes = [
  { path: "/", component: HomePage },
  { path: "/contact", component: ContactPage },
  { path: "/cases", component: CasesPage },
  { path: "/cases/:id", component: CasePage },
  { path: "/about-us", component: AboutUsPage },
  { path: "/services", component: ServicesPage },
  { path: "/services/:slug", component: ServicesPage, props: true },
  { path: "/:slug", component: ContentPage, props: true },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

export default router;
