import { defineStore } from "pinia";
import { fetchAboutUs } from "@/services/aboutUsService";

export const useAboutUsStore = defineStore("aboutUs", {
  state: () => ({
    aboutUs: null,
  }),
  actions: {
    async loadAboutUs() {
      if (!this.aboutUs) {
        this.aboutUs = await fetchAboutUs();
      }
    },
  },
});
