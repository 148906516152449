<template>
  <div
    class="w-[180px] md:w-[360px] h-[70px] md:h-[180px] bg-surfaceDark text-background flex items-center justify-center rounded-3xl px-8"
  >
    <div class="w-[100vw] flex justify-center p-3 h-full">
      <img :src="url" :alt="alt" class="object-contain" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientCard",
  props: {
    url: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: "Client logo",
    },
  },
};
</script>
