<template>
  <div v-if="contact" class="flex flex-col items-center w-full">
    <navigation-bar />
    <hero-banner
      :background-url="contact?.hero?.achtergrond_foto?.url"
      :title="contact?.hero?.titel"
    />
    <div
      class="flex flex-col md:flex-row pt-[92px] md:px-[96px] px-8 max-w-screen w-full justify-between gap-8"
    >
      <!-- contact column -->
      <div class="flex flex-col w-full basis-1/2 grow max-w-[600px]">
        <!-- header -->
        <h2
          class="text-headline-medium w-full max-w-[320px] md:max-w-full mb-3"
        >
          {{ contact?.hero?.titel }}
        </h2>
        <div class="flex gap-4">
          <pressable-surface-button
            v-if="contact?.email"
            :label="contact.email.label"
            :onTap="initiateEmail"
          />
          <pressable-surface-button
            v-if="contact?.telefoonnummer"
            :label="contact.telefoonnummer.label"
            :onTap="initiateCall"
          />
        </div>
        <div v-if="contact?.adres" class="flex flex-col gap-3 pt-10">
          <p class="text-label-large">Kopje koffie?</p>
          <p
            v-if="contact.adres"
            v-html="contact.adres"
            class="text-body-medium text-onSurfaceVariant"
          />
        </div>
        <div class="flex flex-col gap-3 pt-10">
          <p class="text-label-large">Algemene informatie?</p>
          <p
            v-if="contact?.btw_nummer"
            v-html="contact.btw_nummer"
            class="text-body-medium text-onSurfaceVariant"
          />
          <p
            v-if="contact.iban"
            v-html="contact.iban"
            class="text-body-medium text-onSurfaceVariant"
          />
        </div>
      </div>

      <!-- contact form column -->
      <contact-screen-form />
    </div>
    <site-footer />
  </div>
</template>

<script>
import SiteFooter from "@/components/SiteFooter.vue";
import HeroBanner from "@/components/HeroBanner.vue";
import PressableSurfaceButton from "@/components/buttons/PressableSurfaceButton.vue";
import NavigationBar from "@/components/globals/NavigationBar.vue";
import ContactScreenForm from "@/components/ContactScreenForm.vue";
import { useContactStore } from "@/stores/contact";
import { onMounted, computed } from "vue";

export default {
  name: "ContactPage",
  components: {
    SiteFooter,
    HeroBanner,
    PressableSurfaceButton,
    NavigationBar,
    ContactScreenForm,
  },
  methods: {
    initiateCall() {
      window.location.href = "tel:+3172893068";
    },
    initiateEmail() {
      window.location.href = "mailto:info@codios.nl";
    },
  },
  setup() {
    const contactStore = useContactStore();
    onMounted(async () => {
      await contactStore.loadContact();
    });
    const contact = computed(() => contactStore.contact || {});
    return {
      contact,
    };
  },
};
</script>
