<template>
  <section
    class="relative w-full h-full flex flex-col justify-center items-center align-middle"
  >
    <img src="@/assets/404.svg" alt="Page Not Found" class="w-[60%] pb-12" />
    <p class="text-headline-small">Deze pagina bestaat helaas niet :(</p>
    <pressable-button
      class="mt-8"
      :label="'Terug naar home'"
      :onTap="() => $router.push(`/`)"
    />
  </section>
</template>

<script>
import PressableButton from "@/components/buttons/PressableButton.vue";

export default {
  name: "PageNotFoundSection",
  components: {
    PressableButton,
  },
};
</script>
