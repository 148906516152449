import qs from "qs";
import { baseUrl } from "@/config";

const Servicesquery = qs.stringify({
  pLevel: 3,
});

export const fetchServices = async () => {
  const url = new URL("/api/service-pagina?", baseUrl);
  url.search = Servicesquery;

  try {
    const response = await fetch(url.href, { cache: "no-store" });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
