import { defineStore } from "pinia";
import { fetchCases, fetchCase } from "@/services/casesService";

export const useCasesStore = defineStore("cases", {
  state: () => ({
    cases: null,
    case: null,
  }),
  actions: {
    async loadCases() {
      if (!this.cases) {
        this.cases = await fetchCases();
      }
    },
    async loadCase(id) {
      if (!this.case) {
        this.case = await fetchCase(id);
      }
    },
  },
});
