<template>
  <pressable
    :onTap="onTap"
    :press-distance="3"
    class="rounded-full border-2 border-surfaceShadow overflow-hidden w-12 h-12 bg-background flex items-center justify-center"
  >
    <div class="">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6565 17.8857L21.6565 10.3433M21.6565 10.3433L14.114 10.3433M21.6565 10.3433L10.3428 21.657"
          stroke="#0F232A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </pressable>
</template>

<script>
import Pressable from "@/components/globals/Pressable.vue";
export default {
  components: { Pressable },
  name: "PressableArrow",
  props: {
    onTap: {
      type: Function,
      required: true,
    },
  },
};
</script>