<template>
  <div class="flex flex-col items-center w-full flex-[1_0_auto]">
    <navigation-bar />
    <div class="md:px-24 px-6 mt-6 w-full max-w-screen">
      <!-- GRID WITH CASES -->
      <div v-if="cases?.cases" class="grid grid-cols-10 gap-6">
        <!-- HERO CARD -->
        <div class="col-span-10 md:col-span-4 card">
          <h1
            v-html="cases?.titel ?? 'Portfolio'"
            class="md:text-headline-medium text-headline-small mb-4 text-onSurfaceDark"
          />

          <p
            v-html="cases?.ondertitel || '&nbsp;'"
            class="text-label-small-variant md:text-label-large text-primary"
          />
        </div>
        <!-- CASE CARDS -->
        <pressable
          v-for="(caseItem, index) in cases?.cases"
          :key="caseItem.id"
          :class="getColumnSpan(index)"
          :style="
            caseItem?.case_informatie?.hero_achtergrond_foto?.url
              ? backgroundImage(
                  caseItem.case_informatie?.hero_achtergrond_foto.url
                )
              : {}
          "
          class="card"
          :onTap="() => onTap(caseItem.documentId)"
        >
          <div class="absolute inset-0 bg-black opacity-60 z-10"></div>
          <h2
            class="relative z-20 md:text-headline-medium text-headline-small text-onSurfaceDark"
          >
            {{ caseItem?.case_informatie?.naam }}
          </h2>
          <h2
            class="relative z-20 text-label-small-variant md:text-label-large text-onSurfaceDark text-center"
          >
            {{ caseItem?.case_informatie?.slogan }}
          </h2>
          <pressable-arrow
            :onTap="() => onTap(caseItem)"
            class="absolute z-20 top-5 right-5"
          />
        </pressable>
      </div>
      <!-- LOADING -->
      <div v-else class="grid grid-cols-10 gap-6">
        <!-- HERO CARD -->
        <div class="col-span-10 md:col-span-4 card">
          <h1
            class="md:text-headline-medium text-headline-small mb-4 text-onSurfaceDark"
          >
            Portfolio
          </h1>

          <p class="text-label-small-variant md:text-label-large text-primary">
            &nbsp;
          </p>
        </div>
        <!-- PLACEHOLDERS -->
        <div
          v-for="(caseItem, index) in 4"
          :key="caseItem.id"
          :class="getColumnSpan(index)"
          class="card opacity-20"
          :onTap="() => onTap(caseItem)"
        ></div>
      </div>
    </div>
  </div>
  <site-footer />
</template>

<script>
import NavigationBar from "@/components/globals/NavigationBar.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import PressableArrow from "@/components/buttons/PressableArrow.vue";
import Pressable from "@/components/globals/Pressable.vue";
import { useRouter } from "vue-router";
import { useWindowSize } from "@vueuse/core";
import { useCasesStore } from "@/stores/cases";
import { onMounted, computed } from "vue";

export default {
  name: "CasesPage",
  components: {
    SiteFooter,
    NavigationBar,
    PressableArrow,
    Pressable,
  },
  methods: {
    backgroundImage(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
  setup() {
    const { width } = useWindowSize();
    const router = useRouter();

    const onTap = (documentId) => {
      router.push(`/cases/${documentId}`);
    };

    const getColumnSpan = (index) => {
      const columnSpansDesktop = [6, 3, 4, 3, 6, 4, 4, 6, 3]; // Layout for > 1024px
      const columnSpansTablet = [6, 5, 5, 4]; // Layout for 768px - 1024px
      const columnSpansMobile = [10]; // Layout for < 768px

      // Dynamic layout based on screen width
      if (width.value >= 1024) {
        return `col-span-${
          columnSpansDesktop[index % columnSpansDesktop.length]
        }`;
      } else if (width.value >= 768) {
        return `col-span-${
          columnSpansTablet[index % columnSpansTablet.length]
        }`;
      } else {
        return `col-span-${
          columnSpansMobile[index % columnSpansMobile.length]
        }`;
      }
    };

    const casesStore = useCasesStore();
    onMounted(async () => {
      await casesStore.loadCases();
    });
    const cases = computed(() => casesStore.cases);

    return {
      onTap,
      getColumnSpan,
      cases,
    };
  },
};
</script>

<style scoped>
.card {
  @apply bg-surfaceDark relative rounded-3xl flex flex-col items-center justify-center h-[calc(100vw/3.75)] min-h-[160px] max-h-[384px] bg-cover bg-center overflow-hidden;
}
</style>
