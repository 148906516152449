<template>
  <div class="flex flex-col w-full basis-1/2 grow max-w-[600px]">
    <h2 class="text-headline-medium w-full max-w-[320px] md:max-w-full mb-3">
      Of vul het formulier in.
    </h2>
    <!-- form -->
    <form class="flex flex-col" @submit.prevent="submitForm">
      <div class="flex flex-col gap-4 md:flex-row md:pb-8">
        <input-form v-model="formData.name" class="w-full" label="Naam" />
        <input-form
          v-model="formData.company"
          class="w-full"
          label="Bedrijf/organisatie"
        />
      </div>

      <div class="flex flex-col gap-4 md:flex-row md:pb-8">
        <input-form v-model="formData.email" label="E-mailadres" />
        <input-form v-model="formData.phone" label="Telefoonnummer" />
      </div>

      <input-form v-model="formData.message" class="mb-4" label="Je bericht" />
      <div class="flex justify-end">
        <pressable-button
          class="max-w-[140px] relative"
          label="Verstuur"
          @click="submitForm"
        />
        <!-- TODO: implement recaptchav2 on send -->
      </div>
    </form>
  </div>
</template>

<script>
import PressableButton from "@/components/buttons/PressableButton.vue";
import InputForm from "@/components/globals/InputForm.vue";

export default {
  name: "ContactScreenForm",
  components: {
    PressableButton,
    InputForm,
  },
  data() {
    return {
      formData: {
        name: "",
        company: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    async submitForm() {
      // Recaptcha token ophalen (deze moet je implementeren indien nodig)
      const token = "your-recaptcha-token";

      // Object maken zoals verwacht door EZForms
      const data = {
        token: token,
        formName: "Contact Formulier", // Dit kan aangepast worden naar jouw form naam
        formData: this.formData,
      };

      try {
        const response = await fetch(
          "http://localhost:1337/api/ezforms/submit",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          console.log("Formulier succesvol verzonden:", await response.json());
          // Eventueel een notificatie toevoegen voor de gebruiker
        } else {
          console.error(
            "Fout bij het verzenden van het formulier:",
            response.status
          );
        }
      } catch (error) {
        console.error("Er is een fout opgetreden:", error);
      }
    },
  },
};
</script>
