<template>
  <div
    class="fixed h-[100vh] gap-9 w-full px-8 inset-0 bg-surfaceDark z-50 flex flex-col overflow-hidden"
  >
    <div class="flex place-content-between w-full items-center pt-7">
      <img
        src="@/assets/codios-light.png"
        alt="Codios Logo"
        class="h-9 cursor-pointer"
        @click="() => $router.push('/')"
      />
      <svg
        @click="onClose"
        class="cursor-pointer"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.0564 15.0563C15.6666 14.4461 16.6559 14.4461 17.2661 15.0563L25.0001 22.7903L32.7341 15.0564C33.3443 14.4462 34.3336 14.4462 34.9438 15.0564C35.554 15.6666 35.554 16.6559 34.9438 17.2661L27.2099 25L34.9438 32.7339C35.554 33.3441 35.554 34.3334 34.9438 34.9436C34.3336 35.5538 33.3443 35.5538 32.7341 34.9436L25.0001 27.2097L17.2661 34.9437C16.6559 35.5539 15.6666 35.5539 15.0564 34.9437C14.4462 34.3335 14.4462 33.3442 15.0564 32.734L22.7904 25L15.0564 17.266C14.4462 16.6558 14.4462 15.6664 15.0564 15.0563Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="flex flex-col gap-10 text-body-medium text-onSurfaceDark">
      <ul
        class="list-item"
        @click="() => $router.push('/services') && onClose()"
      >
        Services
      </ul>
      <ul class="list-item" @click="() => $router.push('/cases') && onClose()">
        Portfolio
      </ul>
      <ul
        class="list-item"
        @click="() => $router.push('/about-us') && onClose()"
      >
        Over ons
      </ul>
    </div>
    <div>
      <pressable-button
        class="min-w-[140px] mt-3"
        label="Contact"
        :onTap="() => $router.push('/contact') && onClose()"
      />
    </div>
  </div>
</template>

<script>
import PressableButton from "./buttons/PressableButton.vue";

export default {
  name: "NavBarContent",
  components: {
    PressableButton,
  },
  props: {
    onClose: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.list-item {
  @apply cursor-pointer hover:text-primary transition-all;
}
</style>
