import { defineStore } from "pinia";
import { fetchServices } from "@/services/servicesService";

export const useServicesStore = defineStore("services", {
  state: () => ({
    services: null,
  }),
  actions: {
    async loadServices() {
      if (!this.services) {
        this.services = await fetchServices();
      }
    },
  },
});
