import { defineStore } from "pinia";
import { fetchContact } from "@/services/contactService";

export const useContactStore = defineStore("contact", {
  state: () => ({
    contact: null,
  }),
  actions: {
    async loadContact() {
      if (!this.contact) {
        this.contact = await fetchContact();
      }
    },
  },
});
