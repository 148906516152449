import { createApp } from "vue";
import Vue3Lottie from "vue3-lottie";
import App from "./App.vue";
import "./index.css";
import { createPinia } from "pinia";

import router from "./router";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { textMixin } from "./mixins/textMixin";

const pinia = createPinia();

createApp(App)
  .use(pinia)
  .mixin(textMixin)
  .use(Vue3Lottie)
  .use(VueReCaptcha, {
    sitekey: "6LcYJVoqAAAAACjl8lEm_7LXQHeqevzryZQpl3sM",
  })
  .use(router)
  .mount("#app");
