<template>
  <section
    class="relative flex flex-col w-full max-w-screen md:px-[96px] px-6 gap-3 mt-12"
  >
    <div v-if="title && text" class="flex flex-col">
      <h2
        v-html="title"
        class="text-headline-medium text-onSurfaceLight mb-3"
      />
      <p v-html="text" class="text-body-large-variant text-onSurfaceLight" />
    </div>
    <div v-if="image" class="">
      <img class="max-h-[240px] object-cover w-full" :src="image" alt="Image" />
    </div>
  </section>
</template>

<script>
export default {
  name: "TextImageVertSection",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>
