<template>
  <pressable
    :onTap="onTap"
    :press-distance="4"
    class="rounded-[10px] text-onSurfaceVariant border-surfaceShadow border-2 py-4 md:px-4 lg:px-8 flex items-center justify-center"
  >
    <p class="text-button text-wrap">{{ label }}</p>
  </pressable>
</template>

<script>
import Pressable from "@/components/globals/Pressable.vue";

export default {
  components: { Pressable },
  name: "PressableSurfaceButton",
  props: {
    label: {
      type: String,
      default: "Klik hier",
    },
    onTap: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isPressed: false,
    };
  },
};
</script>
