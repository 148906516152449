<template>
  <nav class="flex justify-between items-center">
    <img
      src="@/assets/codios-light.png"
      alt="Codios Logo"
      class="h-9 cursor-pointer"
      @click="$router.push('/')"
    />
    <ul v-if="!isMobileView" class="flex space-x-16 items-center">
      <ul class="flex space-x-12 text-lg">
        <li>
          <a
            class="hover:text-primary text-button-variant cursor-pointer"
            :class="{ 'text-onSurfaceDark': isDarkBackground }"
            @click="$router.push('/services')"
            >Services</a
          >
        </li>
        <li>
          <a
            class="hover:text-primary text-button-variant cursor-pointer"
            :class="{ 'text-onSurfaceDark': isDarkBackground }"
            @click="$router.push('/cases')"
            >Portfolio</a
          >
        </li>
        <li>
          <a
            class="hover:text-primary text-button-variant cursor-pointer"
            :class="{ 'text-onSurfaceDark': isDarkBackground }"
            @click="$router.push('/about-us')"
            >Over ons</a
          >
        </li>
      </ul>
      <PressableButton
        label="Contact"
        @click="
          $router.push('/contact');
          toggleMenu();
        "
      />
    </ul>
    <div v-else @click="toggleMenu">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7291 13.3333C10.7291 12.8155 11.1489 12.3958 11.6666 12.3958H28.3333C28.8511 12.3958 29.2708 12.8155 29.2708 13.3333C29.2708 13.8511 28.8511 14.2708 28.3333 14.2708H11.6666C11.1489 14.2708 10.7291 13.8511 10.7291 13.3333ZM10.7291 20C10.7291 19.4822 11.1489 19.0625 11.6666 19.0625H28.3333C28.8511 19.0625 29.2708 19.4822 29.2708 20C29.2708 20.5177 28.8511 20.9375 28.3333 20.9375H11.6666C11.1489 20.9375 10.7291 20.5177 10.7291 20ZM10.7291 26.6666C10.7291 26.1489 11.1489 25.7291 11.6666 25.7291H28.3333C28.8511 25.7291 29.2708 26.1489 29.2708 26.6666C29.2708 27.1844 28.8511 27.6041 28.3333 27.6041H11.6666C11.1489 27.6041 10.7291 27.1844 10.7291 26.6666Z"
          fill="white"
        />
      </svg>
    </div>
    <!-- Fullscreen menu -->
    <transition name="slide-down">
      <mobile-nav-bar v-if="isMenuOpen" :onClose="toggleMenu" />
    </transition>
  </nav>
</template>

<style scoped>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slide-down-enter-from,
.slide-down-leave-to {
  max-height: 0px;
}

.slide-down-enter-to,
.slide-down-leave-from {
  max-height: 100vh;
  overflow: hidden;
}
</style>

<script>
import { useWindowSize } from "@vueuse/core";
import PressableButton from "./buttons/PressableButton.vue";
import MobileNavBar from "./MobileNavBar.vue";
import { computed } from "vue";

export default {
  name: "NavBarContent",
  components: {
    PressableButton,
    MobileNavBar,
  },
  methods: {
    toggleMenu() {
      if (this.isMobileView) {
        this.isMenuOpen = !this.isMenuOpen;
        document.body.classList.toggle("overflow-hidden");
      }
    },
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  setup() {
    const { width } = useWindowSize();
    const isMobileView = computed(() => width.value < 860);

    return {
      isMobileView,
    };
  },
  props: {
    isDarkBackground: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
