<template>
  <div v-if="content" class="w-full max-w-screen md:px-[96px] px-6">
    <StrapiBlocks :content="content" :modifiers="modifiers" :blocks="blocks" />
  </div>
</template>

<script>
import { StrapiBlocks } from "vue-strapi-blocks-renderer";
import { h } from "vue";

export default {
  name: "WysiwygSection",
  components: {
    StrapiBlocks,
  },
  props: {
    content: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const blocks = {
      // Will include the class "mb-4" on all paragraphs
      paragraph: (props) =>
        h("p", { class: "text-body-medium" }, props.children),
      heading: (props) => {
        const level = props.level || 1;
        const tag = `h${level}`;
        const classMap = {
          1: "text-headline-large",
          2: "text-headline-medium",
          3: "text-headline-small",
        };
        return h(
          tag,
          { class: `${classMap[level] || "text-xl"} pb-3` },
          props.children
        );
      },
    };

    return {
      blocks: blocks,
    };
  },
};
</script>
