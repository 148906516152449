<template>
  <section class="px-6 md:px-24 pt-32 w-full max-w-screen">
    <h3 class="text-primary text-label-large pb-2" v-html="title" />
    <div class="flex justify-between pb-6">
      <h2
        v-html="subtitle"
        class="text-headline-medium max-w-[400px] text-onSurfaceLight"
      />
      <p
        v-html="text"
        class="hidden sm:block text-body-medium max-w-[540px] text-onSurfaceLight"
      />
    </div>
    <li class="flex flex-col md:grid grid-cols-2 gap-6">
      <service-card
        v-for="service in services"
        :key="service.id"
        :title="service.naam"
        :background="service.achtergrond_foto.url"
        :on-tap="() => $router.push(`/services/${service.id}`)"
      />
    </li>
  </section>
</template>

<script>
import ServiceCard from "./ServiceCard.vue";

export default {
  name: "HomeScreenServicesSection",
  components: { ServiceCard },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    services: {
      type: Array,
      required: true,
      validator: (services) => {
        return services.every(
          (service) =>
            typeof service.achtergrond_foto.url === "string" &&
            typeof service.naam === "string"
        );
      },
    },
  },
};
</script>
