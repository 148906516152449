<template v-if="clients">
  <section class="pt-[82px] md:pt-[92px] max-w-screen w-full">
    <div class="px-[24px] md:pl-[96px] md:mb-20 mb-3">
      <h3 class="text-primary text-label-large pb-2">{{ title }}</h3>
      <h2
        v-html="subtitle"
        class="text-headline-medium text-onSurfaceLight"
      ></h2>
    </div>
    <div v-if="clients?.length" class="">
      <vue-marquee-slider
        id="marquee-slider"
        :speed="10000"
        :space="24.0"
        :autoWidth="true"
      >
        <ClientCard
          v-for="(client, index) in clients"
          :key="index"
          :url="client.logo.url"
          :alt="client.naam"
        />
      </vue-marquee-slider>
    </div>
  </section>
</template>

<script>
import { VueMarqueeSlider } from "vue3-marquee-slider";
import ClientCard from "./ClientCard.vue";
import "../../node_modules/vue3-marquee-slider/dist/style.css";

export default {
  name: "HomeScreenClientsSection",
  components: {
    VueMarqueeSlider,
    ClientCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
  },
};
</script>

<script setup></script>
