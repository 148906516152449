import qs from "qs";
import { baseUrl } from "@/config";

const footerQuery = qs.stringify(
  {
    populate: {
      call_to_action: {
        populate: ["knop"],
      },
      contactinformatie: {
        populate: {
          telefoonnummers: true,
          emailaddressen: true,
          socials: {
            populate: ["logo"],
          },
        },
      },
      linkjes: true,
    },
  },
  {
    encodeValuesOnly: true,
  }
);

export const fetchFooter = async () => {
  const url = new URL("/api/footer", baseUrl);
  url.search = footerQuery;

  try {
    const response = await fetch(url.href, { cache: "no-store" });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
